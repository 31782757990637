// extracted by mini-css-extract-plugin
export var btn = "button-module--btn---rqWv";
export var btnDanger = "button-module--btn-danger--7hyxM";
export var btnIcon = "button-module--btn-icon--J70q6";
export var btnPlain = "button-module--btn-plain--lrqxJ";
export var btnPlainV2 = "button-module--btn-plain-v2--M0RTT";
export var btnPreserveText = "button-module--btn-preserve-text--Ff1qi";
export var btnPrimary = "button-module--btn-primary---zZMJ";
export var btnPrimaryAnchor = "button-module--btn-primary-anchor--THLl5";
export var btnSecondary = "button-module--btn-secondary--EfiG7";
export var btnSmall = "button-module--btn-small--onaBO";
export var btnSpinner = "button-module--btn-spinner--nVxVS";
export var btnSuccess = "button-module--btn-success--FDfHX";
export var btnTertiary = "button-module--btn-tertiary--XMpEv";
export var btnText = "button-module--btn-text--D2H65";
export var spin = "button-module--spin--Eei8H";