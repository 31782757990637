import React, { useEffect, useState } from 'react'
import useBreakpoint from 'use-breakpoint'
import { useLocation } from '@reach/router'

import { Footer } from '../../../common/footer'
import { usePrevious } from '../../../../hooks'
import { BREAKPOINTS } from '../../../../constants/global'
import { Paths } from '../../../../constants/structure'

import { isLoggedIn } from '../../../../common/utils/auth'
import { Nav } from '../../../common/nav'
import { Head } from '../../../common/head'

import { NetworkAlert } from '../../../common'
import { ClientOnly } from '../../../common/clientOnly'
import * as styles from './layoutLoggedIn.module.scss'

const LayoutLoggedIn = ({ children }) => {
   const { pathname } = useLocation()
   const { breakpoint } = useBreakpoint(BREAKPOINTS)
   const previousLocationPath = usePrevious(pathname)
   const [menuOpen, setMenuOpen] = useState<boolean>(false)

   const isMobile = breakpoint === 'mobile'

   useEffect(() => {
      if (isMobile) {
         setMenuOpen(false)
      }
   }, [isMobile])

   useEffect(() => {
      if (isMobile && previousLocationPath !== pathname) {
         setMenuOpen(false)
      }
   }, [pathname, previousLocationPath, isMobile])

   if (!isLoggedIn()) return children

   return (
      <ClientOnly>
         <div className={styles.wrapper}>
            <NetworkAlert />
            <div className={styles.sidebar}>
               <Head
                  isExpanded={menuOpen}
                  logoLinkHref={Paths.DASHBOARD}
                  onOpenNav={() => setMenuOpen(!menuOpen)}
               />

               {(menuOpen || !isMobile) && (
                  <div className={styles.navContainer}>
                     <Nav />
                  </div>
               )}

               <div className={styles.footer}>
                  <Footer />
               </div>
            </div>

            <div className={styles.content}>{children}</div>
         </div>
      </ClientOnly>
   )
}

export default LayoutLoggedIn
