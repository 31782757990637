import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { useEffect, useState } from 'react'
import { AuditStatus } from '../../components/views/audits/enums'

export default (): UseAuditsTotals => {
   const request = useAPI()
   const [totals, setTotals] = useState<AuditTotals | null>(null)

   const { data, isLoading, isSuccess, refetch, isRefetching } = useQuery(
      'audits-totals',
      getAllAuditsFromAPI
   )

   async function getAllAuditsFromAPI(): Promise<
      AxiosResponse<AuditTotalsResponse>
   > {
      const params = {
         query: '',
         skip: 0,
      }
      return await request.post(PATH.AUDITS.LIST, params)
   }

   useEffect(() => {
      refetch()
   }, [])

   useEffect(() => {
      if (isSuccess && data?.data?.AuditRecords) {
         const { AuditRecords } = data.data
         const Sent = AuditRecords.filter(
            (audit: AuditWithStatus) => audit.Status === AuditStatus.SENT
         ).length
         const InProgress = AuditRecords.filter(
            (audit: AuditWithStatus) => audit.Status === AuditStatus.IN_PROGRESS
         ).length
         const Received = AuditRecords.filter(
            (audit: AuditWithStatus) => audit.Status === AuditStatus.RECEIVED
         ).length
         const updatedTotals = {
            Sent,
            InProgress,
            Received,
         }
         setTotals(updatedTotals)
      }
   }, [isSuccess])

   return {
      totals,
      isLoading: isLoading || isRefetching,
      isSuccess,
   }
}
