import React, { memo, useMemo } from 'react'

import { Button, LoadingOverlay } from '..'

import * as styles from './widgetBox.module.scss'
import { WidgetBoxProps } from './types'
import CountUp from 'react-countup'

const WidgetBox = ({
   children,
   heading,
   id,
   total,
   showSpinner = false,
   onButtonClick,
}: WidgetBoxProps) => {
   const totalToDisplay = useMemo(
      () => (total && typeof total === 'number' && !isNaN(total) ? total : 0),
      [total]
   )

   const headerClassName = useMemo(
      () => (total === undefined ? styles.centered : ''),
      [total]
   )

   const buttonLabel = useMemo(() => {
      switch (id) {
         case 'Locations':
            return 'Manage Locations'
         case 'News':
            return 'View More'
         default:
            return `View ${id}`
      }
   }, [id])

   return (
      <article className={styles.widget}>
         <header className={headerClassName}>
            <h2>{heading}</h2>
            {id && total !== undefined && (
               <div>
                  <p className={styles.totalsText}>Total {id}</p>
                  <p className={styles.totalsAmount}>
                     <CountUp
                        start={0}
                        end={totalToDisplay}
                        duration={3}
                        useEasing
                     />
                  </p>
               </div>
            )}
         </header>
         <section>
            {showSpinner ? <LoadingOverlay positionAbsolute small /> : children}
         </section>
         {onButtonClick && (
            <footer>
               <Button type="button" variant="text" onClick={onButtonClick}>
                  {buttonLabel}
               </Button>
            </footer>
         )}
      </article>
   )
}

export default memo(WidgetBox)
