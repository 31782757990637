import React from 'react'
import Svg from '../svg/svg'
import { IconButtonProps } from './types'

const IconButton = ({
   id,
   action,
   disabled,
   flipHorizontal = false,
   tooltip,
   iconStroke,
}: IconButtonProps) => {
   const horizonalFlip = flipHorizontal ? ' icon-flip-horizontal' : ''

   return (
      <button
         className={`icon-button icon-${id} ${horizonalFlip}`}
         onClick={action}
         disabled={disabled}
         type="button"
         title={tooltip}
      >
         <Svg id={id} stroke={iconStroke} />
      </button>
   )
}

export default IconButton
