import React, { memo, useCallback, useMemo } from 'react'
import CountUp from 'react-countup'

import * as styles from './widgetListItem.module.scss'
import { Image } from '../..'
import classNames from 'classnames'

const WidgetListItem = ({ label, amount, onClick }: WidgetListItemProps) => {
   const handleClick = useCallback(
      () => onClick(label.toLocaleLowerCase()),
      [label, onClick]
   )

   const imageUrl = useMemo(
      () => `/images/${label.toLocaleLowerCase()}.svg`,
      [label]
   )

   const imageAlt = useMemo(() => `${label} Icon`, [label])

   const labelToDisplay = useMemo(
      () => (label === 'InProgress' ? 'In Progress' : label),
      [label]
   )

   return (
      <li className={styles.item}>
         <button type="button" onClick={handleClick}>
            <div
               className={classNames(styles.imageLabelContainer, {
                  [styles.noRadius]: label === 'Admins',
               })}
            >
               <Image url={imageUrl} alt={imageAlt} width={24} lazyLoading />
               <h3>{labelToDisplay}</h3>
            </div>
            <CountUp start={0} end={amount} duration={3} useEasing />
         </button>
      </li>
   )
}

export default memo(WidgetListItem)
