import React, { memo, useMemo } from 'react'
import classNames from 'classnames'

import * as styles from './tabbedWidget.module.scss'
import { TabbedWidgetProps } from './types'

const TabbedWidget = ({
   tabItems,
   selectedTab,
   setSelectedTab,
}: TabbedWidgetProps) => {
   const selectedTabObject = useMemo(
      () => tabItems.find((tab) => tab.id === selectedTab),
      [tabItems, selectedTab]
   )

   return (
      <>
         <div className={styles.tabs}>
            {tabItems.map((tab) => (
               <button
                  key={tab.id}
                  onClick={() => setSelectedTab(tab.id)}
                  className={classNames(styles.tab, {
                     [styles.tabActive]: selectedTab === tab.id,
                  })}
               >
                  {tab.label}
               </button>
            ))}
         </div>
         <div className={styles.tabContentWrapper}>
            {selectedTabObject?.content}
         </div>
      </>
   )
}

export default memo(TabbedWidget)
