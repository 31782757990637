enum UserRoles {
   SUPERUSER = 1,
   COMPANY = 2,
   ADMIN = 3,
   EMPLOYEE = 4,
   VIRTUAL = 6,
}

const isEmployeeRole = (roles: number[]): boolean =>
   roles.includes(UserRoles.EMPLOYEE)

const isAdminRole = (roles: number[]): boolean =>
   roles.includes(UserRoles.ADMIN)

const isCompanyRole = (roles: number[]): boolean =>
   roles.includes(UserRoles.COMPANY)

const isSuperuserRole = (roles: number[]): boolean =>
   roles.includes(UserRoles.SUPERUSER)

const getHighestRole = (roles: number[]): string => {
   const roleId = roles.reduce(
      (min, current) => (current < min ? current : min),
      roles[0]
   )
   switch (roleId) {
      case UserRoles.SUPERUSER:
         return 'Super User'
      case UserRoles.COMPANY:
         return 'Company'
      case UserRoles.ADMIN:
         return 'Admin'
      case UserRoles.EMPLOYEE:
         return 'Employee'
      case UserRoles.VIRTUAL:
         return 'Vritual'
      default:
         return 'Unknown'
   }
}

export {
   UserRoles,
   isEmployeeRole,
   isAdminRole,
   isCompanyRole,
   isSuperuserRole,
   getHighestRole,
}
