import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { useEffect, useState } from 'react'

export default (): UseConnectionsTotals => {
   const request = useAPI()
   const [totals, setTotals] = useState<ConnectionTotals | null>(null)

   const { data, isLoading, isSuccess, refetch, isRefetching } = useQuery(
      'connections-totals',
      getAllConnectionsFromAPI
   )

   async function getAllConnectionsFromAPI(): Promise<
      AxiosResponse<ConnectionTotalsResponse>
   > {
      const params = {
         query: '',
         skip: 0,
      }
      return await request.post(PATH.CONNECTIONS.LIST, params)
   }

   useEffect(() => {
      refetch()
   }, [])

   useEffect(() => {
      if (isSuccess && data?.data?.Connections) {
         const { Connections } = data.data
         const Employees = Connections.filter(
            (conn: ConnectionWithRole) =>
               conn.Role === 'Employee' || conn.RoleId === '4'
         ).length
         const Virtuals = Connections.filter(
            (conn: ConnectionWithRole) =>
               conn.Role === 'Virtual' || conn.RoleId === '6'
         ).length
         const Admins = Connections.filter(
            (conn: ConnectionWithRole) =>
               conn.Role === 'Admin' || conn.RoleId === '3'
         ).length
         const updatedTotals = {
            Employees,
            Virtuals,
            Admins,
         }
         setTotals(updatedTotals)
      }
   }, [isSuccess])

   return {
      totals,
      isLoading: isLoading || isRefetching,
      isSuccess,
   }
}
