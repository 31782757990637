import React, { memo } from 'react'

import svgLogo from '../../../assets/images/logo-yellow.svg'
import svgTextLogo from '../../../assets/images/logo_text.svg'

const Logo = ({ textOnly = false, width = '180px' }: LogoProps) => (
   <img
      style={{ width }}
      src={textOnly ? svgTextLogo : svgLogo}
      alt="ShareMyToolbox"
   />
)

export default memo(Logo)
