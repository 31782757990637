import { SortDirection } from '../hooks/useFilters/useFilters.type'

export interface UseItemData {
   error?: string
   resetErrorMsg: () => void
   data: []
   get: (
      query?: string,
      skip?: number,
      take?: number,
      filters?: {},
      sortBy?: string,
      sortDirection?: SortDirection
   ) => void
   refresh: () => void
   update?: (obj: any) => void
   add?: (obj: any) => void
   remove?: (obj: any) => void
   query?: string
   totalItems: number
   setPath: (path: {}, cacheIdentifier: string) => void
   isLoading: boolean
   isRefetching: boolean
   isCreating: boolean
   isSuccessCreate: boolean
   isErrorCreate: boolean
   isUpdating: boolean
   isSuccessUpdate: boolean
   isErrorUpdate: boolean
   isRemoving: boolean
   isSuccessRemove: boolean
   isErrorRemove: boolean
   setMockData?: any
}

export interface UseItemViewData {
   error?: string
   data: []
   get: (obj?: {}) => void
}

export interface UseTableData {
   error?: string
   data?: []
   list?: any
   getItemHistoryData?: (str: string) => void
   itemNotesList?: (str: string) => void
   query?: string
   totalItems?: number
   setPath?: (path: {}, cacheIdentifier: string) => void
   setPageOptions?: (options: {}) => void
   isLoading?: boolean
   setMockData?: any
   isSuccess?: boolean
   isRefetching?: boolean
   remove?: (id: {}) => void
   update?: (obj: {}) => void
   isRemoving?: boolean
   isSuccessRemove?: boolean
   isErrorRemove?: boolean
   refetch?: any
   msg?: any
   add?: (payload: any) => void
   isErrorUpdate?: boolean
   errorUpdate?: any
   isErrorAdd?: boolean
   isSuccessAdd?: boolean
   isSuccessUpdate?: boolean
   isUpdating?: boolean
   isAdding?: boolean
}

export interface UseTableWrapper {
   data: []
   columns: any
   totalCount: number
   getItems: () => void
   takeItems: number
   skipItems: number
   setSearchMode: (value: boolean) => void
   searchMode: boolean
   isPageReset: boolean
   setIsPageReset?: (value: boolean) => void
   setPageSized: (value: number) => void
   extraClassName: string
   isLoading: boolean
   searchCriteria: string
   viewValue?: number
   setView?: (value: number) => void
   initialState?: {}
   showActionForm?: any
   toolStatus?: number
   hidePagination?: boolean
   setItemId?: (value: number) => void
   hideResultsTitle?: boolean
   isScrollView?: boolean
   isCart?: boolean
   isListRefetching?: boolean
   setBypass?: any
   getItem?: any
   listViewData?: any
   isListLoading?: boolean
   isListSuccess?: boolean
}

export interface UseCountData {
   data: {}
   isLoading: boolean
   isSuccess?: boolean
   setCountPath: (path: {}, cacheIdentifier: string) => void
}

export interface TotalCount {
   Count: number
}

export interface TotalCountData {
   data: TotalCount[]
}

export interface UseTotalCount {
   data: number
   isLoading: boolean
   isSuccess?: boolean
   setCountPath: (path: {}, cacheIdentifier: string) => void
   getCount: (obj?: any) => void
}

export interface ExportCsvProps {
   error?: string
   data: []
   get: (query?: string, skip?: number, take?: number) => void
   query?: string
   setPath: (path: {}, cacheIdentifier: string) => void
   isLoading: boolean
   setMockData?: any
}

export type GetItemsRequestOptions = {
   query?: string
   skip?: number
   take?: number
   filters?: object
   apiMethod?: 'get' | 'post'
   sortBy?: string
   sortDirection?: SortDirection
}

export type GetItemsViewRequestOptions = {
   Id: number
   ToolLoadId: number | null
   filters?: object
   apiMethod?: 'get' | 'post'
}

export interface PostItemsRequestOptions {
   startDate: any
   endDate: any
   selectedConnections: []
   selectedLocations: []
   selectedStatus: []
   selectedItems: []
   offset: any
   serial: string
   barcode: string
   query?: string
   skip?: number
   take?: number
}

export interface AuditHistoryOptions {
   searchForAuditItem: string
   sentStartDate: any
   sentEndDate: any
   selectedConditions: []
   selectedConfirmationType: []
   selectedItemConfirmation: 0
   offset: number
   selectedConnections: []
   selectedLocations: []
   selectedCategories: []
   selectedStatus: []
   selectedManufacturers: []
   skip: number
   take: number
   query: string
}

export interface ItemOptions {
   categoryId: null
   manufacturerId: null
   toolStatus: null
   connectionId: null
   locationId: null
   skip: 0
   take: 10
   query: null
   groupBy: null
   itemTypes: null
   isSearchForKit: false
   kitID: null
   defaultLocationID: null
   userId: null
   isForMultiselectFilter: false
   selectedStatus: null
   selectedManufacturers: null
   selectedConnections: null
   selectedCategories: null
   selectedLocations: null
   isFetchSelectedAuditItems: false
   selectedToolID: null
   selectedToolLoanID: null
   selectedKitID: null
}

export enum Severity {
   ERROR = 'error',
   WARNING = 'warning',
   INFO = 'information',
   SUCCESS = 'success',
}

export enum ToolStatus {
   ALL = 0,
   AVAILABLE = 1,
   PENDING = 10,
   LOANED = 4,
   UNAVAILABLE = 2,
   EMPLOYEE_RETURN = 8,
}

export enum ToolTypes {
   null,
   'Standard Item',
   'Quantity Item',
   'Kit',
   'Consumable',
}

export enum StatusValue {
   'Available' = 1,
   'Unavailable' = 2,
   'Loaned' = 4,
   'Borrowed' = 8,
   'Pending' = 10,
   'ConsumablesAvailable' = 99,
   'All' = 12,
}

export type Location = {
   Id: number
   Description: string
   Warehouse: boolean
   Address: string
   City: string
   State: string
   ZipCode: string
   PhoneNumber: string
   Latitude: number
   Longitude: number
   Active: boolean
   Archived: boolean
   Default: boolean
   DeletedOn: string
}

export type Item = {
   Permissions: string[]
   FromSameEnterprise: boolean
   DefaultLocation: string
   CurrentLocation: string
   RequestedLocation: string
   RequestedLocationId: number
   LocationID: number
   Id: number
   SerialNumber: string
   Title: string
   Description: string
   AuditItemID: number
   Manufacturer: string
   ManufacturerId?: number
   Barcode: string
   ModelNumber: string
   StatusDesc: string
   Status: number
   PictureURL: string
   LastUpdated: string
   Notifications: number
   NoteCount: number
   DueBackDate: string
   AssignedToUser: string
   OwnerName: string
   ToolLoanId: number
   ToolType: number
   ToolTypeDesc: string
   Quantity: number
   ReorderLevelQuantity: number
   StatusQuantity: number
   WebKitQuantity: number
   AssignedToUserID: string
   KitPrimeKey: number | null
   KitId: number | null
   KitItemID: number | null
   KitQuantity: number | null
   IsKitItem: boolean
   AssignedToKitName: number | null
   DefaultLocationID: number
   localUniqueId: number
   CustomText: string
   IsOwner: boolean
   Category: string
   PurchasePrice: number
   WarrantyDate: string
   IsRentalTool: boolean
   RentalDate: string
   RentalReturnDate: string
   WebSiteURL: string
   AttachmentUrl: string
   LastAuditDate: string
   LastAuditCondition: number
   LastAuditConditionDesc: string
   Vendor: string
   CustomDate1Value: string
   CustomDate2Value: string
   CustomText1Value: string
   CustomText2Value: string
   CustomURL1Value: string
   CustomURL2Value: string
   CustomRentalRate1: number
   CustomRentalRate2: number
   CustomRentalRate3: number
   CustomRentalRate4: number
   TotalValue: string
   PurchaseDate: string
   Latitude: number
   Longitude: number
   quantityForCart?: number
   updateLocation?: boolean
   CustomRentalRate1Value?: any
   CustomRentalRate2Value?: any
   CustomRentalRate3Value?: any
   CustomRentalRate4Value?: any
   AuditNote?: string | null
   CreatedDate: string
   ConditionDesc?: string | null
}

export type Notification = {
   NotificationPermission: number
   IsRecipient: boolean
   Id: number
   ToolId: number
   Text: string
   Detail: string
   SubDetail: string
   UserName: string
   OtherUserId: string
   ImageUrl: string
   Type: number
   DateCreated: string
   Location: any
   ToolNoteId: number
   ToolNoteDueDate: string
   Message: string
   Quantity: number
   ToolType: number
   ToolLoanId: number
}

export enum NotificationTypes {
   Action = 1,
   Reminder = 2,
   Info = 4,
   Global = 8,
}

export type Note = {
   Id: number
   ToolId: number
   ToolName: string
   ToolSerialNumber: string
   ToolBarcode: string
   CreatedBy: string
   CreatedByName: string
   CreatedOn: string
   NoteTypeTitle: string
   NoteTypeId: number
   NoteText: string
   DueDate: string
   Completed: boolean
   CompletedDate: string
   CompletedBy: string
   ToolNotePermission: number
}

export enum ToolNotePermission {
   None = 0,
   View = 1,
   Edit = 2,
   Delete = 4,
}

export type GpsScan = {
   Latitude: number
   Longitude: number
   CreatedByName: string
   CreatedOn: string
   GPSLocationName: string
}

export type SearchObject = {
   offset?: number
   query?: string
   take?: number
   skip?: number
}
