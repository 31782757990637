import React, { memo, useMemo } from 'react'

import { Seo } from '..'

import * as styles from './pageHeader.module.scss'
import { PageHeaderProps } from './types'
import classNames from 'classnames'

const PageHeader = ({
   metaTitle,
   subtitle,
   title,
   children,
   extraMargin,
}: PageHeaderProps) => {
   const className = classNames(styles.pageHeader, {
      [styles.extraMargin]: extraMargin,
   })

   const seoTitle = useMemo(() => metaTitle ?? title, [metaTitle, title])

   return (
      <header className={className}>
         <Seo title={seoTitle} />
         <div>
            <h1 className={styles.pageHeaderTitle}>{title}</h1>
            {subtitle && (
               <p className={styles.pageHeaderSubtitle}>{subtitle}</p>
            )}
         </div>
         <div className={styles.pageHeaderRightSection}>{children}</div>
      </header>
   )
}
export default memo(PageHeader)
